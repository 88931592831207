@import "./../theme/colors.scss";

.default {
  height: 34px;
  padding: 5px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  color: $base-color-white;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
}

.primary {
  background-color: $brand-primary;

  &:disabled {
    background-color: $base-secondary-pale !important;
    color: $base-secondary-2 !important;

    &:hover,
    &:focus {
      background-color: $base-secondary-pale !important;
      color: $base-secondary-2 !important;
    }
  }

  &:hover {
    background-color: $brand-additional-dark !important;
    color: $base-color-white !important;
  }

  &:focus {
    background-color: $brand-additional-dark !important;
    color: $brand-secondary-1 !important;
  }
}

.secondary {
  background-color: $base-color-white;
  color: $brand-primary;
  border: 1px solid $base-secondary-2;

  &:disabled {
    background-color: $base-color-white !important;
    color: $base-secondary-2 !important;
    border: 1px solid $base-secondary-pale !important;

    &:hover,
    &:active {
      background-color: $base-color-white !important;
      color: $base-secondary-2 !important;
      border: 1px solid $base-secondary-pale !important;
    }
  }

  @media (hover: hover) {
    &:hover {
      background-color: $brand-secondary-pale !important;
      color: $brand-primary !important;
      border: 1px solid $brand-primary !important;
    }
  }

  @media (hover: none) and (pointer: coarse) {
    &:hover {
      background-color: $base-color-white !important;
      color: $brand-primary !important;
      border: 1px solid $base-secondary-2 !important;
    }
  }

  &:active {
    background-color: $base-secondary-pale !important;
    color: $brand-additional-dark !important;
    border: 1px solid $brand-additional-dark !important;

    @media (hover: none) {
      background-color: $brand-secondary-pale !important;
      color: $brand-primary !important;
      border: 1px solid $brand-primary !important;
    }
  }

  &.no-hover {
    @media (hover: none) {
      &:hover {
        background-color: $base-color-white !important;
        color: $brand-primary !important;
        border: 1px solid $base-secondary-2 !important;
      }
    }
  }
}
.danger {
  background-color: $base-color-white;
  color: $red-primary;
  border: 1px solid $red-primary;

  &:disabled {
    background-color: $base-color-white !important;
    color: $base-secondary-2 !important;
    border: 1px solid $base-secondary-pale !important;

    &:hover,
    &:focus {
      background-color: $base-color-white !important;
      color: $base-secondary-2 !important;
      border: 1px solid $base-secondary-pale !important;
    }
  }

  &:hover {
    background-color: $red-secondary-1 !important;
    color: $base-color-white !important;
    border: 1px solid $red-secondary-1 !important;
  }

  &:focus {
    background-color: $red-additional-dark !important;
    color: $base-color-white !important;
    border: 1px solid $red-additional-dark !important;
  }
}
