@import "../../../../ui-kit/theme/colors.scss";
$mobile: 320px;
$tablet: 768px;
$s: 1024px;
$m: 1280px;
$l: 1440px;
$xl: 1920px;
$xxl: 2560px;

.modal-payment .ant-modal-content {
  border-radius: 0px;
  padding: 24px !important;
  height: 100vh;
  margin-top: 18px;

  @media screen and (min-width: $s) {
    padding: 40px !important;
    width: auto;
    margin-top: auto;
  }
}

.modal-payment .ant-modal-body {
  height: 100%;
}

.modal-select .ant-select-selector {
  border-radius: 0px;
}

.payment-collapse .ant-collapse-item .ant-collapse-header {
  padding-left: 0;
}

.payment-collapse .ant-collapse-item .ant-collapse-content-box {
  padding-left: 0;
}

.picker-operation .ant-picker-input > input::placeholder {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: $base-secondary-1 !important;
}

.picker-operation .ant-picker-input > input {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}
