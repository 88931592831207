$mobile: 320px;
$tablet: 768px;
$s: 1024px;
$m: 1280px;
$l: 1440px;
$xl: 1920px;
$xxl: 2560px;

.modal-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media screen and (min-width: $s) {
    gap: 32px;
    padding: 20px 16px;
  }
}

.button-item-enter {
  border-radius: 4px;
  color: #cf133c;
  height: 32px;
  border: 1px solid #cf133c;
  width: 50%;
}

.button-item-download {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media screen and (min-width: $tablet) {
    width: 50%;
  }
}

.button-item-cancel {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: $tablet) {
    width: 50%;
  }
}

.buttons-row {
  display: flex;
  gap: 10px;

  flex-direction: column;

  @media screen and (min-width: $tablet) {
    flex-direction: row;
  }
}

.list-wrapper {
  display: flex;
  flex-direction: column;

  gap: 24px;
}

.list-text {
  display: flex;
  flex-direction: column;
}

.text-source {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #505050;
}

.text-input {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #141414;
}

.necessary {
  color: #cf133c;
  font-family: "Inter";
  font-size: 10px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  align-self: flex-start;
}

.text-date {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #6159ff;
}

.input-item {
  display: flex;
  flex-direction: column;

  width: 100%;

  @media screen and (min-width: $s) {
    gap: 2px;
  }
}

.description-text {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #505050;
  display: flex;
  gap: 4px;
}

.default-text {
  color: #141414;
}

.heading-text {
  font-family: "Inter";
  font-size: 20px !important;
  font-weight: 600;
  line-height: 28px !important;
  text-align: left;
  color: #141414;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  max-width: 330px;

  @media screen and (min-width: $s) {
    margin-top: 8px;
    font-size: 24px !important;
    line-height: 32px !important;
    max-width: none;
  }
}
