@import "../../../../ui-kit/theme/colors.scss";

$mobile: 320px;
$tablet: 768px;
$s: 1024px;
$m: 1280px;
$l: 1440px;
$xl: 1920px;
$xxl: 2560px;

.text-description {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #505050;
}

.title-description {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #505050;
}

.ant-modal {
  height: -webkit-fill-available;
}

.modal-style {
  height: 100%;
  top: 0;
  overflow: auto;
}

.modal-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.operation-inner {
  display: flex;
  flex-direction: column;
  max-width: 440px;
}

.inputs-row {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.input-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.description-item {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.select-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 440px;
}
.default-text {
  color: #141414;
}

.cancel-button {
  height: 34px;
  padding: 8px 16px;
  border-radius: 4px;
  width: 100%;
  color: #6159ff;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.save-button {
  height: 34px;
  padding: 8px 16px;
  border-radius: 4px;
  width: 100%;
  background: #6159ff;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-payment-inner {
  width: 136px;
  height: 30px;
  padding: 6px 8px 6px 12px;
  border-radius: 2px;
  border: 1px solid #505050;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.20000000298023224px;
  text-align: center;
  color: #141414;
}

.payment-inner {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.footer-button {
  display: flex;
  margin-top: 24px;
  gap: 8px;
  flex-direction: column;
  @media screen and (min-width: $tablet) {
    flex-direction: row;
    margin-top: 20px;
    margin-bottom: 0px;
    gap: 16px;
  }
}
.remark-text {
  color: #505050;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.20000000298023224px;
  text-align: left;
}

.text-row {
  display: flex;
  gap: 48px;
}

.text-left-part {
  width: 120px;
  color: #8c8c8c;
}

.text-right-part {
  text-align: left;
  width: 200px;
}

.form-inner {
  width: 100%;
}

.hint-text {
  font-family: "Inter";
  font-size: 10px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #8c8c8c;
}

.error-text {
  font-size: 10px;
  line-height: 18px;
  color: $red-primary;
  font-weight: 600;
}

.inputs-row {
  display: flex;
}

.description-inner {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.necessary {
  color: #cf133c;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
