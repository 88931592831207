@import "../../../../ui-kit/theme/colors.scss";

.modal-payment .ant-modal-content {
  border-radius: 0px;
  padding: 40px;
  height: 100vh;
}

.modal-payment .ant-modal-body {
  height: 100%;
}

.modal-select .ant-select-selector {
  border-radius: 0px;
}

.payment-collapse .ant-collapse-item .ant-collapse-header {
  padding-left: 0;
}

.payment-collapse .ant-collapse-item .ant-collapse-content-box {
  padding-left: 0;
}

.picker-off .ant-picker-input > input::placeholder {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: $base-secondary-1 !important; // Используйте вашу переменную здесь
}
