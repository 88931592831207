@import "./../theme/colors.scss";

.default-input-auto :-webkit-autofill {
  background: #fff !important;
}

.default-input-auto :-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

.default-input-auto :-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

.default-input-auto :-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}
