@import "../../../../../ui-kit/theme/colors.scss";
@import "../../../../../ui-kit/theme/fonts.scss";

$mobile: 320px;
$tablet: 768px;
$s: 1024px;
$m: 1280px;
$l: 1440px;
$xl: 1920px;
$xxl: 2560px;

.input-item {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
}

.title-description {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #505050;
}

.description-item {
  margin-bottom: 24px;
}

.error-text {
  font-size: 10px;
  line-height: 18px;
  color: $red-primary;
  font-weight: 600;
}

.necessary {
  color: #cf133c;
  font-family: "Inter";
  font-size: 10px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.modal-wrapper {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $s) {
    gap: 8px;
  }
}

.generate-button {
  border-radius: 4px;
  background: #6159ff;
  color: #fff;
  height: 100%;
  width: 100%;
}

.inputs-inner {
  display: flex;
  flex-direction: column;
}

.form-inn {
  width: 100%;
}
.text-description {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}

.default-text {
  color: #141414;
}

.text-disabled {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: $base-secondary-1;
}

.title-text {
  font-family: "Inter";
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: $base-primary-dark;
}
