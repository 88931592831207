$mobile: 320px;
$tablet: 768px;
$s: 1024px;
$m: 1280px;
$l: 1440px;
$xl: 1920px;
$xxl: 2560px;

.right-sider-wrapper {
  background-color: #fff;
  border-left: 1px solid #d9d9d9;
  padding-top: 20px;
  padding-left: 24px;
  padding-right: 16px;
}

.remark-wrapper {
  display: flex;
  width: 100%;
  gap: 16px;
  min-height: 42px;
  gap: 4px;
}

.remark-button {
  border-radius: 2px;
  background: #6159ff;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.20000000298023224px;
  text-align: center;
}

.feedback-button {
  border: 1px solid #9984ff;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.20000000298023224px;
  text-align: center;
  color: #6159ff;
}

.heading-text {
  font-size: 34px;
  line-height: 36px;
  font-weight: 500;
  letter-spacing: -1px;
  margin-top: 0;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  gap: 8px;
  padding: 24px;

  @media screen and (min-width: $m) {
    margin: 32px 24px 0px 274px;
    padding: 0;
    gap: 24px;
  }
}

.info-wrapper {
  width: 100%;
  padding-bottom: 32px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  border-bottom: 1px solid #d9d9d9;
}

.info-row {
  display: flex;
  gap: 40px;
  justify-content: space-between;
}

.info-item {
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 320px;
}

.text-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.005em;
  text-align: left;
  color: #8c8c8c;
}

.text-link {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.005em;
  text-align: left;
  color: #6159ff;
  border-bottom: 1px solid;
}

.text-inner {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #141414;
}

.off-button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.off-button {
  border: 1px solid #ff8095;
  border-radius: 2px;
  color: #f52245;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.20000000298023224px;
  text-align: center;
}

.mcd-wrapper {
  max-width: 240px;
  max-height: 160px;
  padding: 28px 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  background: linear-gradient(
    247.04deg,
    #f5f4ff -2.37%,
    rgba(227, 226, 255, 0.31) 100%
  );
}

.mcd-button {
  border-radius: 4px;
  background: #6159ff;
  color: #fff;
  height: 44px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.20000000298023224px;
  text-align: center;
}

.mcd-text {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.005em;
  text-align: center;
  color: #505050;
}

.download-button {
  border: 1px solid #d9d9d9;
  width: 240px;
  height: auto;
  border-radius: 4px;
  display: flex;
  gap: 8px;
  justify-content: space-between;
}

.download-text-inner {
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: left;
}

.download-text-title {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.20000000298023224px;
  text-align: left;
  color: #8c8c8c;
}

.download-icon {
  font-size: 14px;
  color: #6159ff;
  align-self: center;
}

.download-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.buttons-row {
  display: flex;
  gap: 16px;
  max-width: 500px;
}

.buttons-row-item {
  flex: 1;
  border-radius: 2px;
}

.sider-notification {
  margin-top: 40px;
  background: linear-gradient(
    247.04deg,
    #efeff6 -2.37%,
    rgba(236, 235, 243, 0.31) 100%
  );
  width: 238px;
  padding: 16px;
  border-radius: 8px;
  gap: 16px;
  display: flex;
  flex-direction: column;
}

.sider-notification-text {
  color: #8c8c8c;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.005em;
  text-align: left;
}

.sider-icon {
  font-size: 19px;
  color: #8c8c8c;
}

.mcd-text-inner {
  display: flex;
  flex-direction: column;
}

.button-make {
  border: 1px solid #9984ff;
  color: #9984ff;
}
