.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner::after {
  width: 4.87px !important;
  height: 12.09px !important;
  border: 1px solid #fff !important;
  border-top: 0 !important;
  border-inline-start: 0 !important;
  top: 45%;
}

.ant-form-item-explain-error {
  display: flex;
}
